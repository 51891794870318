/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.UsedPartsInfoFilter = (function() {

    /**
     * Namespace UsedPartsInfoFilter.
     * @exports UsedPartsInfoFilter
     * @namespace
     */
    var UsedPartsInfoFilter = {};

    UsedPartsInfoFilter.UsedPartsInfoFilterModel = (function() {

        /**
         * Properties of a UsedPartsInfoFilterModel.
         * @memberof UsedPartsInfoFilter
         * @interface IUsedPartsInfoFilterModel
         * @property {string|null} [quoteNo] UsedPartsInfoFilterModel quoteNo
         * @property {string|null} [dateFrom] UsedPartsInfoFilterModel dateFrom
         * @property {string|null} [dateTo] UsedPartsInfoFilterModel dateTo
         * @property {string|null} [itemNo] UsedPartsInfoFilterModel itemNo
         * @property {string|null} [itemDescription] UsedPartsInfoFilterModel itemDescription
         * @property {string|null} [poNo] UsedPartsInfoFilterModel poNo
         * @property {string|null} [vendor] UsedPartsInfoFilterModel vendor
         * @property {string|null} [sortColumn] UsedPartsInfoFilterModel sortColumn
         * @property {string|null} [sortOrder] UsedPartsInfoFilterModel sortOrder
         * @property {number|null} [pageIndex] UsedPartsInfoFilterModel pageIndex
         * @property {number|null} [pageSize] UsedPartsInfoFilterModel pageSize
         */

        /**
         * Constructs a new UsedPartsInfoFilterModel.
         * @memberof UsedPartsInfoFilter
         * @classdesc Represents a UsedPartsInfoFilterModel.
         * @implements IUsedPartsInfoFilterModel
         * @constructor
         * @param {UsedPartsInfoFilter.IUsedPartsInfoFilterModel=} [p] Properties to set
         */
        function UsedPartsInfoFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UsedPartsInfoFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.quoteNo = null;

        /**
         * UsedPartsInfoFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.dateFrom = null;

        /**
         * UsedPartsInfoFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.dateTo = null;

        /**
         * UsedPartsInfoFilterModel itemNo.
         * @member {string|null|undefined} itemNo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.itemNo = null;

        /**
         * UsedPartsInfoFilterModel itemDescription.
         * @member {string|null|undefined} itemDescription
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.itemDescription = null;

        /**
         * UsedPartsInfoFilterModel poNo.
         * @member {string|null|undefined} poNo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.poNo = null;

        /**
         * UsedPartsInfoFilterModel vendor.
         * @member {string|null|undefined} vendor
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.vendor = null;

        /**
         * UsedPartsInfoFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.sortColumn = null;

        /**
         * UsedPartsInfoFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.sortOrder = null;

        /**
         * UsedPartsInfoFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.pageIndex = null;

        /**
         * UsedPartsInfoFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        UsedPartsInfoFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UsedPartsInfoFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _itemNo.
         * @member {"itemNo"|undefined} _itemNo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_itemNo", {
            get: $util.oneOfGetter($oneOfFields = ["itemNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _itemDescription.
         * @member {"itemDescription"|undefined} _itemDescription
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_itemDescription", {
            get: $util.oneOfGetter($oneOfFields = ["itemDescription"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _poNo.
         * @member {"poNo"|undefined} _poNo
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_poNo", {
            get: $util.oneOfGetter($oneOfFields = ["poNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _vendor.
         * @member {"vendor"|undefined} _vendor
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_vendor", {
            get: $util.oneOfGetter($oneOfFields = ["vendor"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UsedPartsInfoFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @instance
         */
        Object.defineProperty(UsedPartsInfoFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified UsedPartsInfoFilterModel message. Does not implicitly {@link UsedPartsInfoFilter.UsedPartsInfoFilterModel.verify|verify} messages.
         * @function encode
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @static
         * @param {UsedPartsInfoFilter.IUsedPartsInfoFilterModel} m UsedPartsInfoFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UsedPartsInfoFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(10).string(m.quoteNo);
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(18).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(26).string(m.dateTo);
            if (m.itemNo != null && Object.hasOwnProperty.call(m, "itemNo"))
                w.uint32(34).string(m.itemNo);
            if (m.itemDescription != null && Object.hasOwnProperty.call(m, "itemDescription"))
                w.uint32(42).string(m.itemDescription);
            if (m.poNo != null && Object.hasOwnProperty.call(m, "poNo"))
                w.uint32(50).string(m.poNo);
            if (m.vendor != null && Object.hasOwnProperty.call(m, "vendor"))
                w.uint32(58).string(m.vendor);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(66).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(74).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(80).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(88).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified UsedPartsInfoFilterModel message, length delimited. Does not implicitly {@link UsedPartsInfoFilter.UsedPartsInfoFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @static
         * @param {UsedPartsInfoFilter.IUsedPartsInfoFilterModel} message UsedPartsInfoFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UsedPartsInfoFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UsedPartsInfoFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {UsedPartsInfoFilter.UsedPartsInfoFilterModel} UsedPartsInfoFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UsedPartsInfoFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.UsedPartsInfoFilter.UsedPartsInfoFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.quoteNo = r.string();
                    break;
                case 2:
                    m.dateFrom = r.string();
                    break;
                case 3:
                    m.dateTo = r.string();
                    break;
                case 4:
                    m.itemNo = r.string();
                    break;
                case 5:
                    m.itemDescription = r.string();
                    break;
                case 6:
                    m.poNo = r.string();
                    break;
                case 7:
                    m.vendor = r.string();
                    break;
                case 8:
                    m.sortColumn = r.string();
                    break;
                case 9:
                    m.sortOrder = r.string();
                    break;
                case 10:
                    m.pageIndex = r.int32();
                    break;
                case 11:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a UsedPartsInfoFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof UsedPartsInfoFilter.UsedPartsInfoFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {UsedPartsInfoFilter.UsedPartsInfoFilterModel} UsedPartsInfoFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UsedPartsInfoFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return UsedPartsInfoFilterModel;
    })();

    return UsedPartsInfoFilter;
})();

module.exports = $root;
