<template>
  <div v-if="$userInfo">
    <app-detail-header
      :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <usedpartsinfo-list-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(entity, index) in entitySummaries"
                :key="entity.quoteId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ entity.quoteNo }}</td>
                <td>{{ $filters.formatDateLocale(entity.quoteDate, $userInfo.locale) }}</td>
                <td>{{ entity.itemNo }}</td>
                <td>{{ entity.itemDescription }}</td>
                <td>{{ entity.purchaseOrderNo }}</td>
                <td>{{ entity.vendor }}</td>
                <td>{{ entity.fullAddress }}</td>
                <td>{{ $filters.formatDateLocale(entity.acquiredOn, $userInfo.locale) }}</td>
                <td>{{ entity.itemComment }}</td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import UsedPartsInfoListSideMenu from './UsedPartsInfoListSideMenu'
import UsedPartsInfoService from './UsedPartsInfoService'
import { AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import UsedPartsInfoValidation from './UsedPartsInfoValidation'
import { Columns } from './columns'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import StoreMixin from './storeMixin'
import { DateTime } from 'luxon'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'UsedpartsinfoListView',
  components: {
    AppDetailHeader,
    [UsedPartsInfoListSideMenu.name]: UsedPartsInfoListSideMenu,
    BulmaTable
  },
  mixins: [DateTimeFiltersMixin, StoreMixin, UsedPartsInfoValidation],
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      filter: {
        quoteNo: '',
        dateFrom: '',
        dateTo: '',
        itemNo: '',
        itemDescription: '',
        poNo: '',
        vendor: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getEntitySummaries()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    edit(id, event) {
      this.$router.push({
        name: 'UsedPartInformationDetail',
        params: { quoteId: id }
      })
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Used Parts Information', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.quoteNo = ''
      this.filter.dateFrom = ''
      this.filter.dateTo = ''
      this.filter.itemNo = ''
      this.filter.itemDescription = ''
      this.filter.poNo = ''
      this.filter.vendor = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await UsedPartsInfoService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    print1() {
      const params = {
        CompanyID: this.$userInfo.companyId,
        QuoteNo: this.filter.quoteNo,
        QuoteStart: this.filter.dateFrom === '' ? '' : DateTime.fromISO(this.filter.dateFrom).toISODate(),
        QuoteEnd: this.filter.dateTo === '' ? '' : DateTime.fromISO(this.filter.dateTo).toISODate(),
        ItemNo: this.filter.itemNo,
        ItemDesc: this.filter.itemDescription,
        PONo: this.filter.poNo,
        Vendor: this.filter.vendor,
        PageSize: this.totalRows,
        PageIndex: 1,
        SortColumn: this.filter.sortColumn,
        SortOrder: this.filter.sortOrder,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      const emailer = new Emailer()
      emailer.subject = 'Used Parts Information'
      emailer.reportName = 'UsedPartsInformationList'
      this.addEmailer(emailer)
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId:  this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>
