import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Quote', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Quote Date', 'quoteDate', true, 'desc', 'left'),
  new TableColumn('Item No.', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Item Desc.', 'itemDescription', true, 'desc', 'left'),
  new TableColumn('PO No.', 'purchaseOrderNo', true, 'asc', 'left'),
  new TableColumn('Vendor', 'vendor', true, 'asc', 'centered'),
  new TableColumn('Address', 'fullAddress', true, 'desc', 'left'),
  new TableColumn('Acquired On', 'acquiredOn', true, 'desc', 'left'),
  new TableColumn('Comment', 'itemComment', true, 'desc', 'left')
]

export { Columns }
