import FqApi from '@/services/fq-api'
//import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseUsedPartsInfoFilter(filter)
    const url = `usedpartsinfo/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseUsedPartsInfoFilter(filter) {
    const entityProto = require('../../assets/proto/usedpartsinfo/UsedPartsInfoFilter.proto')
    const filterMessage = entityProto.UsedPartsInfoFilter.UsedPartsInfoFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
